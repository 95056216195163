/* eslint-disable quotes */
export const appConfig =
{
  "apiKey": "AIzaSyBbrRV7pq97mus2mFrdoyicTCwUNa97MTI",
  "appId": "1:517863311998:web:05ed28434b2aed8e59a62f",
  "authDomain": "schooldog-i-crisp-ga.firebaseapp.com",
  "measurementId": "G-4RLN68Y7V2",
  "messagingSenderId": "517863311998",
  "project": "schooldog-i-crisp-ga",
  "projectId": "schooldog-i-crisp-ga",
  "showGaBranding": true,
  "storageBucket": "schooldog-i-crisp-ga.appspot.com"
}
